@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  min-height: 100vh;
  background-color: #191919;
}

.react-datepicker {
  background-color: #1F1F1F;
  color: white;
  border: 1px solid #373737;
}

.react-datepicker__header {
  background-color: #2D2D2D;
  border-bottom: 1px solid #373737;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: white;
}

.react-datepicker__day:hover {
  background-color: #3D3D3D;
}

.react-datepicker__day--selected {
  background-color: #4A4A4A;
}

.react-datepicker__day--disabled {
  color: #6D6D6D;
}

.custom-popper {
  z-index: 9999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}